@import "./_common-variables.sass"
@import './utils/_all.scss'
@import './typography.scss'
@import './background-colors.scss'

html
    overflow-y: auto // hide scrollbar if not needed

.title
  color: var(--black-grey)
  font-size: 1rem
  font-weight: inherit
  line-height: 1.375
  font-family: var(--font-family-medium)

  &:not(:last-child)
    margin-bottom: 0


.title:not(.is-spaced) + .subtitle
  margin-top: 0

.subtitle
  color: var(--grey)
  font-size: 0.875rem
  font-weight: 400
  line-height: 1.21
  word-break: break-word
  margin-top: 0

.is-family-bold
    font-family: var(--font-family-bold)
.is-family-semiBold
    font-family:  var(--font-family-semiBold)
.is-family-light
    font-family:  var(--font-family-light)
.is-family-medium
    font-family:  var(--font-family-medium)
.is-family-regular
    font-family:  var(--font-family-regular)
.is-flex
    display: flex !important
.is-relative
    position: relative
.is-absolute
    position: absolute
.is-fixed
    position: fixed
.is-overflow-hidden
    overflow: hidden
.is-direction-column
    align-items: start !important
    justify-content: center !important
    flex-direction: column !important
.is-direction-row
    align-items: center !important
    justify-content: start !important
    flex-direction: row !important
.is-space-between
    justify-content: space-between !important
.is-justify-center
    justify-content: center !important
.has-no-border
    border: none !important
.is-marginless
    margin: 0 !important
.is-cursor-pointer
    cursor: pointer
.is-fullwidth
    width: 100%
.is-fullheight
    height: 100%
.is-loading-overlay
    position: relative
    color: transparent !important
    &:after
        @include loader
        position: absolute !important
        top: 0
        left: 0
.has-margin-centered
  margin: auto
.is-link
  cursor: pointer
  width: 100%
.is-v-align-start
  align-self: flex-start
.is-align-end
  margin-left: auto
  justify-content: flex-end
  p
    text-align: end
  span
    text-align: end // TODO remove if we replace span with p
.is-align-start
  margin-right: auto
  justify-content: flex-start
.text
    line-height: 1.3rem
    margin-bottom: 1.2rem
.is-underlined
    text-decoration: underline
.is-transparent-background
    background: transparent !important
.is-borderless
    border: none !important
.is-paddingless
    padding: 0 !important
.is-paddingless-top
    padding-top: 0 !important
.is-paddingless-bottom
    padding-bottom: 0 !important

.section-title
    color: var(--primary)
    font-size: 1.125rem
    line-height: 1.277
    font-family: var(--font-family-semiBold)

.is-background-overlay
    z-index: 15
.is-page-title
    font-size: $page-title-font-size

.items-align-center
    align-items: center !important

.text-align-center
  text-align: center !important

$spacings: (none 0px, xxs 4px, xs 8px, s 12px, m 16px, l 18px, xl 20px, xxl 24px, 3xl 28px )

@each $spacing, $size in $spacings
  .has-spacing-bottom-#{$spacing}
    margin-bottom: $size
  .has-spacing-top-#{$spacing}
    margin-top: $size
  .has-spacing-left-#{$spacing}
    margin-left: $size
  .has-spacing-right-#{$spacing}
    margin-right: $size
  .has-spacing-y-#{$spacing}
    margin-bottom: $size
    margin-top: $size
  .has-spacing-x-#{$spacing}
    margin-left: $size
    margin-right: $size
  .has-spacing-#{$spacing}
    margin: $size

  .has-border-radius-#{$spacing}
    border-radius: $size



/* Text Color Helpers */
.has-text-netatmo
  color: var(--netatmo-orange) !important
.has-text-primary
  color: var(--primary) !important
.has-text-secondary
  color: var(--secondary) !important
.has-text-alert
  color: var(--red-alert) !important
.has-text-success
  color: var(--green-confirmation) !important
.has-text-danger
  color: var(--red-alert) !important

/* Black-White Scale Color Helpers */
.has-text-black
  color: var(--black-space) !important
.has-text-black-grey
  color: var(--black-grey) !important
.has-text-dark-grey
  color: var(--dark-grey) !important
.has-text-medium-grey
  color: var(--medium-grey) !important
.has-text-grey
  color: var(--grey) !important
.has-text-grey-light
  color: var(--light-grey) !important
.has-text-silver
  color: var(--silver) !important
.has-text-bright-silver
  color: var(--bright-silver) !important
.has-text-white
  color: var(--white) !important
.has-text-gs-white
  color: var(--gs-white) !important

.has-text-olive-green
  color: var(--olive-green) !important
.has-text-orange-alert
  color: var(--orange-alert)


.has-text-centered
  text-align: center !important
.has-text-right
  text-align: right!important
/* Background Color Helpers */
.has-background-netatmo
  background-color: var(--netatmo-orange) !important
.has-background-orange-alert
  background-color: var(--orange-alert) !important
.has-background-primary
  background-color: var(--primary) !important
.has-background-secondary
  background-color: var(--secondary) !important
.has-background-alert
  background-color: var(--red-alert) !important
.has-background-success
  background-color: var(--green-confirmation) !important
.has-background-danger
  background-color: var(--red-alert) !important

/* Black-White Scale Background Color Helpers */
.has-background-black
  background-color: var(--black-space) !important
.has-background-black-grey
  background-color: var(--black-grey) !important
.has-background-dark-grey
  background-color: var(--dark-grey) !important
.has-background-medium-grey
  background-color: var(--medium-grey) !important
.has-background-grey
  background-color: var(--grey) !important
.has-background-grey-light
  background-color: var(--light-grey) !important
.has-background-silver
  background-color: var(--silver) !important
.has-background-bright-silver
  background-color: var(--bright-silver) !important
.has-background-white
  background-color: var(--white) !important

.has-background-eco-green-transparent
  background-color: var(--eco-green-transparent) !important
.has-background-eco-green
  background-color: var(--eco-green) !important

.has-background-bright-blue-grey
  background-color: var(--bright-blue-grey) !important
.has-background-blue
  background-color: var(--blue) !important

.has-background-olive-green
  background-color: var(--olive-green) !important

.has-background-ruby-pink
    background-color: var(--ruby-pink) !important

.has-background-greeny-blue
    background-color: var(--greeny-blue) !important

.has-background-salmon-pink
    background-color: var(--salmon-pink) !important

.has-background-amethyst-purple
  background-color: var(--amethyst-purple) !important

.has-background-light-blue
    background-color: var(--light-blue) !important

.has-background-contract-base
  background-color: var(--contract-base) !important

.has-background-contract-peak
    background-color: var(--contract-peak) !important

.has-background-contract-off-peak
  background-color: var(--contract-off-peak) !important

.has-background-contract-super-off-peak
  background-color: var(--contract-super-off-peak) !important

.has-background-contract-weekend
  background-color: var(--contract-weekend) !important

.has-background-contract-resale
  background-color: var(--contract-resale) !important

.has-background-contract-free
  background-color: var(--contract-free) !important

.has-background-contract-production
  background-color: var(--contract-production) !important

.has-background-contract-custom1
  background-color: var(--contract-custom1) !important

.has-background-contract-custom2
  background-color: var(--contract-custom2) !important

.has-background-contract-custom3
  background-color: var(--contract-custom3) !important

.has-background-contract-custom4
  background-color: var(--contract-custom4) !important

.has-background-contract-custom5
  background-color: var(--contract-custom5) !important

.has-background-contract-custom6
  background-color: var(--contract-custom6) !important

.has-background-contract-conflict
  background-color: var(--red-alert) !important
  background-image: repeating-linear-gradient(45deg,transparent,transparent 1px,rgba(0,0,0,.4) 2px,transparent 3px,transparent 4px)

.has-background-contract-not-defined
  background-color: var(--grey-30) !important

.is-sticky-top
    position: sticky !important
    z-index: 2
    top: 0 !important
.is-space-around
    justify-content: space-around !important

.has-shared
  background-color: #2dd160 !important

.subtitle
  color: var(--medium-grey)

.is-overlay
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

// Input border
.has-input-border, .has-input-border-secondary
    border-bottom: 2px solid var(--primary)
    width: 30%
    margin: auto
    input
        width: 75px
        text-align: center
    input[type="number"]
        min-width: 60px
        font-size: 18px
        -moz-appearance: textfield

    input[type="number"]::-webkit-outer-spin-button
        display: none
        -webkit-appearance: none
        margin: 0
    input[type="number"]::-webkit-inner-spin-button
        display: none
        -webkit-appearance: none
        margin: 0

.has-input-border-secondary
    border-bottom: 2px solid var(--secondary)
    input, input[type=number], input[type=text]
        color: var(--secondary)
        width: 100%

.is-rounded
  border-radius: 0.25rem

.is-xlarge-icon
  width: 6rem
  height: 6rem
  font-size: 6rem

//size
.is-size-1
  font-size: 3rem

.is-size-2
  font-size: 2.5rem

.is-size-3
  font-size: 2rem

.is-size-4
  font-size: 1.5rem

.is-size-5
  font-size: 1.125rem

.is-size-6
  font-size: 1rem

.is-size-7
  font-size: 0.875rem

.is-size-8
  font-size: 0.75rem

.tag
  align-items: center
  background-color: #4078f8
  border-radius: 4px
  color: #fff
  display: inline-flex
  font-size: .875rem
  height: 2em
  justify-content: center
  line-height: 1.5
  padding-left: .75em
  padding-right: .75em
  white-space: nowrap


.is-flex-grow-0
  flex-grow: 0
.is-flex-grow-1
  flex-grow: 1
.is-flex-grow-2
  flex-grow: 2
.is-flex-grow-3
  flex-grow: 3
.is-flex-grow-4
  flex-grow: 4
.is-flex-grow-5
  flex-grow: 5

.is-flex-shrink-0
  flex-shrink: 0
.is-flex-shrink-1
  flex-shrink: 1
.is-flex-shrink-2
  flex-shrink: 2
.is-flex-shrink-3
  flex-shrink: 3
.is-flex-shrink-4
  flex-shrink: 4
.is-flex-shrink-5
  flex-shrink: 5

.is-flex-basis-0
  flex-basis: 0

.is-flex-none
  flex: none !important
