// lay-panel-content,
// lay-panel-list
//     &.panel:not(:last-child) > .panel-block:last-child
//         border-bottom: $panel-item-border

button
    border-radius: $button-border-radius !important

.is-circle-button
    border-radius: $buttoncircle !important

.panel
    box-shadow: none
