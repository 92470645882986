@use '@angular/material' as mat;
@use 'sass:map';

$theme-foreground-palette: (
  secondary-text:    var(--medium-grey),
  text:              var(--black-grey),
);

@include mat.core();

$typography: mat.m2-define-typography-config(
  $body-2: mat.m2-define-typography-level($font-size: 1rem),
  $body-1: mat.m2-define-typography-level($font-size: 1rem),
  $font-family: 'ProximaNova-Regular',
  $caption: mat.m2-define-typography-level($font-size: 1rem),
  $button: mat.m2-define-typography-level($font-size: 0.875rem, $font-family: 'ProximaNova-SemiBold'),
);

$light-palette: (
50: var(--primary),
100: var(--primary),
200: var(--primary),
300: var(--primary),
400: var(--primary),
500: var(--primary),
600: var(--primary),
700: var(--primary),
800: var(--primary),
900: var(--primary),
contrast: (
  50: #f4f4f4,
  100: #f4f4f4,
  200: #f4f4f4,
  300: #f4f4f4,
  400: #f4f4f4,
  500: #f4f4f4,
  600: #f4f4f4,
  700: #f4f4f4,
  800: #f4f4f4,
  900: #f4f4f4,
)
);

$light-primary: mat.m2-define-palette($light-palette, 500);
$light-accent: mat.m2-define-palette($light-palette, 200, 100, 400);

$light-theme: mat.m2-define-light-theme((
color: (
  primary: $light-primary,
  accent: $light-accent,
),
typography: $typography,
));

$light-theme: map.deep-merge($light-theme, (color: (foreground: $theme-foreground-palette)));

@include mat.all-component-themes($light-theme);

$dark-palette: (
50: var(--primary),
100: var(--primary),
200: var(--primary),
300: var(--primary),
400: var(--primary),
500: var(--primary),
600: var(--primary),
700: var(--primary),
800: var(--primary),
900: var(--primary),
contrast: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
)
);

$dark-primary: mat.m2-define-palette($dark-palette, 500);
$dark-accent: mat.m2-define-palette($dark-palette, 200, 100, 400);

$dark-theme: mat.m2-define-dark-theme((
color: (
  primary: $dark-primary,
  accent: $dark-accent,
),
typography: $typography,
));

$dark-theme: map.deep-merge($dark-theme, (color: (foreground: $theme-foreground-palette)));

[data-theme="dark"] {
  @include mat.all-component-colors($dark-theme);
}

// Override datepicker color
.mat-datepicker-content {
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--primary);
}

.mat-datepicker-content .mat-calendar-body-in-range {
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-disabled-state-text-color: white;
}

.mat-calendar-body-cell:hover {
  --mat-datepicker-calendar-date-text-color: white;
}
